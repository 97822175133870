import './root.css';
import './style.css';
import OnlineUser from './OnlineUser';
import MenuLeft from './templates/MenuLeft';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faChevronDown, faUserAlt, faEdit, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
export default function MainTemplate({ children }) {
	const [dropdownProfile, setDropdownProfile] = useState(false)
	const handleDropdownProfile = () => {
		setDropdownProfile(dropdown => !dropdown)
		console.log(dropdownProfile);
		
	}
	return (
		<>
			<header>
				<section className="header-logo d-flex align-items-center justify-content-center" style={style_main_template.section_left}>
					The Talenthub System
				</section>
				<section style={style_main_template.section_right}>
					<FontAwesomeIcon icon={faUserAlt} /> Kittithat <FontAwesomeIcon icon={faChevronDown} onClick={()=>handleDropdownProfile()} />
					<div className={`dropdown-profile-setting ${dropdownProfile == false ? 'd-none' : ''}`}>
						<ul>
							<li><FontAwesomeIcon icon={faUserAlt} /> ข้อมูลส่วนตัว</li>
							<li><FontAwesomeIcon icon={faEdit} /> เปลี่ยนข้อมูล</li>
							<li><FontAwesomeIcon icon={faRefresh} /> เปลี่ยนรหัสผ่าน</li>
						</ul>
					</div>
				</section>
			</header>
			<nav>
				<MenuLeft />
			</nav>
			<main>{children}</main>
			<OnlineUser />
		</>
	)

}
const style_main_template = {
	section_left: {
		marginInlineStart: '40px',
	},
	section_right: {
		marginInlineEnd: '40px',
		position: "relative",
		color: "var(--text-secondary)"
	},
	
}


import { useState, useEffect } from 'react';
import config from '../configs/api';
import BladeTemplate from '../MainTemplate';
import {faFloppyDisk, faTrashCan, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function AddUser() {
	const [getUsername, setUsername] = useState()
	const [getPassword, setPassword] = useState()
	const [getPhoneNummber, setPhoneNumber] = useState()
	const [getFirstname, setFirstname] = useState()
	const [getLastname, setLastname] = useState()
	const [getTableList, setTableList] = useState([])

	const submit_register = (e) => {
		e.preventDefault()
		fetch("http://thetalenthub.xyz:8080/user/register", {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				username: getUsername,
				password: getPassword,
				firstname: getFirstname,
				lastname: getLastname,
				phone_number: getPhoneNummber
			}),
		})
			.then((res) => res.json())
			.then((result) => {
				console.log(result)
				//		localStorage.setItem("token", '123');
			})
			.catch((error) => console.error('Error:', error));
	};
	const fetchData = async () => {
		const fetchdata = await fetch("http://thetalenthub.xyz:8080/user/table-list")
		const response = await fetchdata.json();
		setTableList(response.response)
	}
	useEffect(()=>{
		fetchData()
	},[])


	return (
		<>
			<BladeTemplate>
			 <div className="navbar-top">
				 <span>บทบาทสิทธิ์การใช้งาน (Roles)</span>
			 </div>
			<div className="container-fluid mt-3">
					<div className="row">
						<div className="col">
							<h2 style={style_register.h2}>สร้างผู้ใช้</h2>
						</div>
					</div>
					<form method="post" onSubmit={submit_register}>
					<div className="row form">
						<div className="col-12 col-md-8 col-lg-8">
							<div className="row mt-2">
								<div className="col col-md-12 col-lg-12">
									<label for="username">Username</label>
									<input  name="username" id="username" onChange={(e) => setUsername(e.target.value)} className="form-control" required />
								</div>
							</div>
							<div className="row mt-2">
								<div className="col col-md-12 col-lg-12">
									<label for="password">Password</label>
									<input type='password' name="password" id="password" onChange={(e) => setPassword(e.target.value)} className="form-control" required />
								</div>
							</div>
							<div className="row mt-2">
								<div className="col col-md-12 col-lg-12">
									<label for="phone_number">Phone Number</label>
									<input name="phone_number" id="phone_number" onChange={(e) => setPhoneNumber(e.target.value)} className="form-control" required />
								</div>
							</div>
							<div className="row mt-2">
								<div className="col col-md-12 col-lg-12">
									<label for="firatname">First Name</label>
									<input name="firstname" id="firstname" onChange={(e) => setFirstname(e.target.value)} className="form-control" required />
								</div>
							</div>
							<div className="row mt-2">
								<div className="col col-md-12 col-lg-12">
									<label for="lastname">Last Name</label>
									<input name="lastname" id="lastname" onChange={(e) => setLastname(e.target.value)} className="form-control" required />
								</div>
							</div>
							<div className="row mt-3">
								<div className="col-12 col-md-12 col-lg-12">
									<button className="btn btn-primary"><FontAwesomeIcon icon={faFloppyDisk}  /> Save here</button>
								</div>
							</div>
						</div>
					</div>
					</form>
					<div className="row list mt-3">
						<div className="col-12 col-md-12 col-lg-12">
							<table className='table'>
								<thead>
									<tr>
										<th>#</th>
										<th>First name</th>
										<th>Last name</th>
										<th>Phone number</th>
										<th>Username</th>
										<th>Management</th>
									</tr>
								</thead>
								<tbody>
									{getTableList.map((row,index)=>(
										<tr>
											<td>{index+=1}</td>
											<td>{row.firstname}</td>
											<td>{row.lastname}</td>
											<td>{row.phone_number}</td>
											<td>{row.username}</td>
											<td>
												<button className="btn btn-warning m-1"><FontAwesomeIcon icon={faPenToSquare} /></button>
												<button className="btn btn-danger m-1"><FontAwesomeIcon icon={faTrashCan} /></button>
											</td>
										</tr>
									))}
								</tbody>
							</table>	
						</div>
					</div>

				</div>
			</BladeTemplate>
		</>
	)
}
const style_register = {
	h2: {
		color: '#003366',
		fontSize: '24px',
		fontWeight: 'bold',
		textAlign: 'left',
		marginBottom: '20px',
	}
}
export default AddUser;

function ProtectRouter ({children}) {
	const token = localStorage.getItem('token');

	if(!token){
		return (<>Login</>)
	}
	return (
		<>
			{children}
		</>
	);


}
export default ProtectRouter;

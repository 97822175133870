import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEarthAsia } from '@fortawesome/free-solid-svg-icons';
import ShowIcon from "./templates/ShowIcon";

export default function OnlineUser(){
    return (
        <>
            <div className="wrapper-onlineuser">
                <div className="title-online mb-3">ผู้ใช้งานที่ใช้งาน</div>
                <div className="user-list d-flex justify-content-between align-items-center">
                    <span style={{fontSize: "0.8rem"}}><ShowIcon img={'logo/logo-people.png'} /> Kittithat </span> 
                    <FontAwesomeIcon icon={faEarthAsia} style={{color: "#00ff00b2",fontWeight: "bold", fontSize: "0.8rem"}} />
                </div>
                <div className="user-list d-flex justify-content-between align-items-center">
                    <span style={{fontSize: "0.8rem"}}><ShowIcon img={'logo/logo-people.png'} /> Kittithat </span> 
                    <FontAwesomeIcon icon={faEarthAsia} style={{color: "#00ff00b2",fontWeight: "bold", fontSize: "0.8rem"}} />
                </div>
                <div className="user-list d-flex justify-content-between align-items-center">
                    <span style={{fontSize: "0.8rem"}}><ShowIcon img={'logo/logo-people.png'} /> Kittithat </span> 
                    <FontAwesomeIcon icon={faEarthAsia} style={{color: "#00ff00b2",fontWeight: "bold", fontSize: "0.8rem"}} />
                </div>
            </div>
        </>
    )
}


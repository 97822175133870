export default function ShowIcon({img}){
    return (
        <>
            <img src={ process.env.PUBLIC_URL + "/" + img} style={style.img} alt="user-profile" />
        </>
    )
}
const style = {
    img: {
        width: "22px",
        height: "22px",
        borderRadius: "5px",
        marginRight: "5px"
    }
}
import MainTemplate from "../MainTemplate";
import Topic from "../templates/Topic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faRotate } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import Swal from 'sweetalert2'
import api from "../configs/api";
import { useParams,useNavigate } from "react-router-dom"
export default function RoleEdit() {
    const [roleName, setRoleName] = useState()
    const [roleDescription, setRoleDescription] = useState()
    const [obtionRoles, setObtionRoles] = useState([])
    const [roleData, setRoleData] = useState([])
    const [active, setActive] = useState()

    const [optionCreate, setOptionCreate] = useState(false)
    const [optionEdit, setOptionEdit] = useState()
    const [optionDelete, setOptionDelete] = useState()
    const [optionView, setOptionView] = useState()
    const [optionActive, setOptionActive] = useState()
    const { id } = useParams();
    const navigate = useNavigate();
    

	const optionList = [
		optionCreate && 'create',
		optionEdit && 'edit',
		optionDelete && 'delete',
		optionView && 'views'
	].filter(Boolean)
	const fetchData = async () => {

        const fetchData = await fetch(api.url_endpoint + `/role/edit/${id}`, { method: 'get' })
        const response = await fetchData.json()
        if (response.status === true && response.message === 'edit') {
            setRoleName(response.response.role_name)
            setRoleDescription(response.response.role_description)
            setObtionRoles(JSON.parse(response.response.permission_temp))
            const setOption = JSON.parse(response.response.permission_temp)
            setOptionCreate(setOption.includes('create'))
            setOptionEdit(setOption.includes('edit'))
            setOptionDelete(setOption.includes('delete'))
            setOptionView(setOption.includes('views'))
            setActive(response.response.is_active)
        }

    }
    const payload = {
        role_name: roleName,
        role_description: roleDescription,
        permission_temp: JSON.stringify(optionList),
        is_active: active,
        id: id
    }
    const updateItem =  (e) => {

        e.preventDefault();
    	console.log(optionList)
		Swal.fire({
            title: 'ต้องการอัพเดทข้อมูล ใช่หรือไม่ !',
            icon: 'success',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: "Save"

        }).then(successData=>{
            if (successData.isConfirmed) {
                const updateData =  fetch(api.url_endpoint + '/role/update',{
                    method: 'post',
                    headers: {
                        'Content-Type': "application/json"
                    },
                    body: JSON.stringify(payload)
                })
                navigate('/system/roles')
                
            }
        })
        
    }

  

    useEffect(function () {
        fetchData()
    }, [])
   
    return (

        <>
            <MainTemplate>
                <div className="navbar-top">
                    {/* <span>เพิ่มบทบาท (Roles)</span> */}
                    <span>แก้ไขบทบาทสิทธิ์การใช้งาน (Edit Role)</span>
                </div>
                <div className="container-fluid">
                    <Topic text="แก้ไขบทบาทสิทธิ์การใช้งาน (Edit Role)" />
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-12">
                            <form method="post" onSubmit={updateItem}>
                                <div className="row mt-2">
                                    <div className="col">
                                        <label htmlFor="">ชื่อบทบาท</label>
                                        <input type="text" className="form-control" value={roleName} onChange={(e)=>setRoleName(e.target.value)}/>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col">
                                        <label htmlFor="">รายละเอียดบทบาท</label>
                                        <input type="text" className="form-control" value={roleDescription} onChange={(e)=>setRoleDescription(e.target.value)} />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col">
                                        <label htmlFor="">สิทธิ์การใช้งาน</label><br />

                                        <input type="checkbox" id="create" checked={optionCreate} onChange={()=>setOptionCreate(data=>!data)} /><label htmlFor="create">&nbsp; Create</label> <br />
                                        <input type="checkbox" id="edit" checked={optionEdit} onChange={()=>setOptionEdit(data=>!data)} /><label htmlFor="edit">&nbsp; Edit</label> <br />
                                        <input type="checkbox" id="delete" checked={optionDelete} onChange={()=>setOptionDelete(data=>!data)} /><label htmlFor="delete">&nbsp; Delete</label> <br />
                                        <input type="checkbox" id="views" checked={optionView} onChange={()=>setOptionView(data=>!data)} /><label htmlFor="views">&nbsp; Views</label> <br />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col">
                                        <label htmlFor="">เปิดใช้งาน</label><br />
                                        <input type="radio" id="Y" name="isActive" checked={active === 'Y'} onChange={()=>setActive('Y')} /><label htmlFor="Y">&nbsp; ใช้งาน</label> <br />
                                        <input type="radio" id="N" name="isActive" checked={active === 'N'}  onChange={()=>setActive('N')} /><label htmlFor="N">&nbsp; ไม่ใช้งาน</label> <br />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col">
                                        <button type="submit" className="btn btn-primary"><FontAwesomeIcon icon={faSave} /> บันทึกข้อมูล</button>
                                        <button type="reset" className="btn btn-warning mx-2"><FontAwesomeIcon icon={faRotate} /> ล้างฟอร์ม</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </MainTemplate>
        </>
    )
}

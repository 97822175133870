import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import TestUse from './TestUse';
import SystemLogin from './components/Systems/Users/SystemLogin';
import MainTemplate from './components/Systems/MainTemplate';
import TestTemplate from './components/Systems/templates/tests/TestTemplate';
import reportWebVitals from './reportWebVitals';
import { createRoot } from "react-dom/client";
import {
	createBrowserRouter,
	RouterProvider,
} from "react-router-dom";
import ProtectRouter from './components/Middleware/ProtectRouter';
import Permission from './components/Systems/Permission';
import Roles from './components/Systems/Roles/Roles';
import GetLocation from './GetLocation';
import RoleEdit from './components/Systems/Roles/RoleEdit';
import AddUsers from "./components/Systems/Users/AddUsers"
const router = createBrowserRouter([
	{
		path: "/test",
		element: (<ProtectRouter><App /></ProtectRouter>)
		//element: (<App />)
	},
	{
		path: '/tests',
		element: <testtemplate />
	},
	{
		path: '/system/login',
		element: <SystemLogin />
	},
	{
		path: '/system',
		element: <MainTemplate />
	},
	{
		path: '/system/permission',
		element: <Permission />
	},
	{
		path: '/system/roles',
		element: <Roles />
	},
	{
		path: '/system/roles/edit/:id',
		element: <RoleEdit />
	},
	{
		path: '/map',
		element: <GetLocation />
	},
	{
		path: '/users/add',
		element: <AddUsers />
	}


]);
createRoot(document.getElementById("root")).render(
	<RouterProvider router={router} />
);  
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

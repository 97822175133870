const lang_user = {
    header_name: "ระบบจัดการ HR" ?? "HR Management",
    header_menu: {
        contact: "ติดต่อนักพัฒนา" ?? "Contact Developer",
        packages: "ซื้อแพ๊คเกต" ?? "Packages"
    },
    form: {
        title: "เข้าใช้งานระบบ" ?? "Login System",
        username: "ผู้ใช้งาน" ?? "Username",
        password: "รหัสผ่าน" ?? "Password",
        btnSubmit: "เข้าสู่ระบบ" ?? "Login",
        btnReset: "ล้าง" ?? "Reset",
    }
}
export default lang_user;
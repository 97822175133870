import { useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs, faServer, faChevronUp, faChalkboardUser, faFileLines } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

export default function MenuLeft() {
    const [collapseMenu1, setCollapseMenu1] = useState(true)
    const [collapseMenu2, setCollapseMenu2] = useState(false)
    const [collapseMenu3, setCollapseMenu3] = useState(false)

    const handle_collapse_menu = (menu) => {
        if (menu === 'menu1') {
            setCollapseMenu1(collapse1 => !collapse1);
        } else if (menu === 'menu2') {
            setCollapseMenu2(collapse2 => !collapse2);

        } else if (menu === 'menu3') {
            setCollapseMenu3(collapse3 => !collapse3);
        }
    }
    return (
        <>
            <div className="parent-menu">
                <div className="nav-menu-title">
                    <h6><FontAwesomeIcon icon={faChalkboardUser} /> ใช้งานระบบ</h6>
                    <div className="mav-menu-collapse" onClick={() => handle_collapse_menu("menu1")}>
                        <FontAwesomeIcon icon={faChevronUp} style={collapseMenu1 == true ? style_main_template.rotate_collapse_minus : style_main_template.rotate_collapse_plus} />
                    </div>
                </div>
                <div className={`child-menu ${collapseMenu1 == true ? "" : "d-none"}`}>
                    <ul>
                        <li><FontAwesomeIcon icon={faServer} /> ผู้ใช้งาน</li>
                        <li><FontAwesomeIcon icon={faServer} /> พนักงาน</li>
                        <li><FontAwesomeIcon icon={faServer} /> ติดตามงาน</li>
                    </ul>
                </div>

            </div>
            <div className="parent-menu">
                <div className="nav-menu-title">
                    <h6><FontAwesomeIcon icon={faCogs} /> เมนู Admin</h6>
                    <div className="mav-menu-collapse" onClick={() => handle_collapse_menu('menu3')}>
                        <FontAwesomeIcon icon={faChevronUp} style={collapseMenu3 == true ? style_main_template.rotate_collapse_minus : style_main_template.rotate_collapse_plus} />
                    </div>
                </div>
                <div className={`child-menu ${collapseMenu3 == true ? "" : "d-none"}`}>
                    <ul>
                        <li><FontAwesomeIcon icon={faCogs} /> <Link to="/system/roles">บทบาท (Roles)</Link></li>
                        <li><FontAwesomeIcon icon={faCogs} /> <Link to="/system/permission">สิทธิ์การใช้งาน (Permission)</Link></li>
                        <li><FontAwesomeIcon icon={faCogs} /> ผู้ใช้งาน</li>
                    </ul>
                </div>

            </div>
            <div className="parent-menu">
                <div className="nav-menu-title">
                    <h6><FontAwesomeIcon icon={faFileLines} /> รายงาน</h6>
                    <div className="mav-menu-collapse" onClick={() => handle_collapse_menu('menu2')}>
                        <FontAwesomeIcon icon={faChevronUp} style={collapseMenu2 == true ? style_main_template.rotate_collapse_minus : style_main_template.rotate_collapse_plus} />
                    </div>
                </div>
                <div className={`child-menu ${collapseMenu2 == true ? "" : "d-none"}`}>
                    <ul>
                        <li><FontAwesomeIcon icon={faServer} /> ผู้ใช้งาน</li>
                        <li><FontAwesomeIcon icon={faServer} /> พนักงาน</li>
                        <li><FontAwesomeIcon icon={faServer} /> ติดตามงาน</li>
                    </ul>
                </div>

            </div>
        </>
    )
}
const style_main_template = {
	rotate_collapse_minus: {
		transform: 'rotate(-180deg)',
		fontWeight: 'bold',
		transition: '.3s'
	},
	rotate_collapse_plus: {
		transform: 'rotate(0deg)',
		fontWeight: 'bold',
		transition: '.3s'

	}
}


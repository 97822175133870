import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import lang_user from "../configs/lang/lang_user";
import '../style.css'
import { faRefresh, faSignIn } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import api from "../configs/api";

export default function SystemLogin(){
	const [username, setUsername] = useState()
	const [password, setPassword] = useState()
	const data = {
		username: username,
		password: password
	}
	const payload = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(data)
	}
	const handleSubmitLogin = async () => {
			const response = await fetch(api.url_endpoint, payload);
			const data = await response.json();
			console.log(data);
	}
	return(
		<>
			<main className="container">
				<div className="row">
					<div className="wrapper-login">
						<h1 className="pt-4">{lang_user.form.title}</h1>
						<div className="px-5">
							<div className="row mt-2">
								<div className="col-12 col-md-12 col-lg-12">
									<label htmlFor="username">{lang_user.form.username}</label><br />
									<input type="text" name="username" id="username" onChange={e=>setUsername(e.target.value)} className="form-control mt-2" />
								</div>
							</div>
							<div className="row mt-2">
								<div className="col-12 col-md-12 col-lg-12">
									<label htmlFor="password">{lang_user.form.password}</label><br />
									<input type="text" name="password" id="password" onChange={e=>setPassword(e.target.value)} className="form-control mt-2" />
								</div>
							</div>
							<div className="row mt-3">
								<div className="col-12 col-md-12 col-lg-12">
									<div className="d-grid gap-2">
										<button type="submit" onClick={()=>handleSubmitLogin()} className="btn btn-primary"><FontAwesomeIcon icon={faSignIn} /> {lang_user.form.btnSubmit}</button>
										<button type="reset" className="btn btn-warning"><FontAwesomeIcon icon={faRefresh} /> {lang_user.form.btnReset}</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</>
	);
}

import MainTemplate from "./MainTemplate";
import Topic from "./templates/Topic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faRotate } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import  Swal  from 'sweetalert2'
import Pagination from "./templates/Pagination";
import api from "./configs/api";
export default function Permission() {
    const current_page_num = Array.from({ length: 10 }, (_, index) => index + 1)
    // /permission/post
    const [permission_name, setPermissionName] = useState()
    const [permission_level, setPermissionLevel] = useState([])
    const [permission_description, setPermissionDescription] = useState()
    const [fetchDataPermission, setFetchDataPermission] = useState([])
    const [getError, setError] = useState(false)
    const [getErroMessager, setErrorMessage] = useState(false)
    
    const data = {
        permission_name: permission_name,
        permission_description: permission_description,
        permission_level: JSON.stringify(permission_level)
    }
    useEffect(() => {
        fetchData()
    }, [])
   
    const fetchData = () => {
        fetch(api.url_endpoint + '/permission/table-list')
            .then(response => response.json())
            .then(data => {
                if(data.statusCode == 200){
                    setFetchDataPermission(data.response)
                }else{
                   Swal.fire({
                    title: 'ไม่สามารถดึงข้อมูลได้',
                    icon: 'danger'
                   })
                }
            })
            .catch(error => console.log(error));
    }
    
    const handleSubmit = (e) => {
        e.preventDefault()
        Swal.fire({
            title: "ต้องการบันทึกข้อมูลตอนนี้เลยใช่ไหม",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: "Save",
            denyButtonText: `Don't save`
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(api.url_endpoint + '/permission/post', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                })
                .then(response => response.json())
                .then(data => {
                    if (data.status == 201) {
                        Swal.fire("สำเร็จ!", "", "success");
                    }
                })
                .catch(error => console.log(error));
            } else if (result.isDenied) {
                Swal.fire("ยกเลิกการบันทึกสำเร็จ !", "", "info");
            }
        });
    }
    return (
        
        <>
            <MainTemplate>
                <div className="navbar-top">
                    <span>เพิ่มสิทธิ์การใช้งาน</span>
                    <span>ตั้งค่าสิทธิ์การใช้งาน</span>
                </div>
                <div className="container-fluid">
                    <Topic text="เพิ่มสิทธิ์การใช้งานระบบ" />
                    <form onSubmit={handleSubmit} method="post">
                        <div className="row mt-3">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="">สิทธิ์การใช้งาน</label>
                                        <input type="text" className="form-control mt-2" onChange={(e) => setPermissionName(e.target.value)} required />
                                    </div>
                                </div>
								<div className="row mt-3">
									<div className="col">
										<label htmlFor="role">บทบาทสิทธิ์การใช้งาน</label>
                                        <select className="form-control mt-2" >
                                            <option value="">กรุณาเลือก</option>
                                        </select>
									</div>
								</div>
                                <div className="row mt-3">
                                    <div className="col">
                                        <label htmlFor="">รายละเอียดสิทธิ์การใช้งาน</label>
                                        <textarea rows={8} className="form-control mt-2" onChange={(e) => setPermissionDescription(e.target.value)} required />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col">
                                        <label htmlFor="">ระดับของสิทธิ์</label>
                                        {permission_level.length > 0 ? (
                                            permission_level.filter(filterData=> filterData != '').map((data, index)=>{
                                                return (
                                                    <li key={index}>{data}</li>
                                                )
                                            })
                                        ) : ''}
                                        <select name="" id="" className="form-control mt-2" onChange={(e) => {
                                            const selected = e.target.value;
                                            if(selected && !permission_level.includes(selected)){
                                                setPermissionLevel(value=>[...value, e.target.value])
                                            }else{
                                                Swal.fire({
                                                    title: 'คุณเลือกข้อมูลซ้ำ',
                                                    icon: 'danger'
                                                })
                                            }
                                        }} required>
                                            <option value="">กรุณาเลือก</option>
                                            <option value="create">เพิ่มข้อมูล</option>
                                            <option value="edit">แก้ไขข้อมูล</option>
                                            <option value="delete">ลบข้อมูล</option>

                                        </select>
                                    </div>
                                    
                                </div>
                              
                                <div className="row mt-3">
                                    <div className="col">
                                        <button type="submit" className="btn btn-primary"><FontAwesomeIcon icon={faSave} /> บันทึกข้อมูล</button>
                                        <button type="reset" className="btn btn-warning mx-2"><FontAwesomeIcon icon={faRotate} /> ล้างฟอร์ม</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="row mt-5">
                        <div className="col-12 col-md-12 col-lg-12">
                            <table className="table table-bordered">
                                <thead>
                                <tr className="bg-info">
                                    <th>#</th>
                                    <th>Permission Name</th>
                                    <th>ระดับของสิทธิ์</th>
                                </tr>
                                </thead>
                                <tbody>
                                {fetchDataPermission.length > 0 ? (
                                    fetchDataPermission.map((element, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{element.permission_name}</td>
                                                <td>{element.permission_level}</td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan="3">ไม่มีข้อมูล</td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/* <Pagination pagination={current_page_num} pageAll={10} /> */}
                </div>
            </MainTemplate>
        </>
    )
}
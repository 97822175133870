import React, { useState, useEffect } from 'react';

const GetLocation = () => {
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [dateTime, setDateTime] = useState(null);
	const mapp = () => {
		console.log(1);
		 	const map = navigator.geolocation.getCurrentPosition(
			(success)=>{
				var newDate = new Date();
				var getTime = newDate.getHours()+':'+newDate.getMinutes()+':'+newDate.getSeconds();
				var month = (newDate.getMonth()+1);
				var getNewDateFormat = newDate.getFullYear() + '-' + ((month <= 9) ? '0'+month : month)  + '-'+ newDate.getDay();
				var dataMap = {latitude: success.coords.latitude, longitude: success.coords.longitude, isDate: getNewDateFormat, isTime: getTime, user: 1}
				setLocation(dataMap)

				},
			(error)=>{console.log( error)}
			)
	}

				console.log(location)
  return (
    <div>
		<div className="card">
			<div className="card-header">Check in here</div>
			<div className="card-body">
				you checkin before go to web 
			</div>
			<div className="card-footer">
				
				<button onClick={()=>mapp()} className="btn btn-secondary">Checkin</button>
			</div>
		</div>
    </div>
  );
};

export default GetLocation;


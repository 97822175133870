function Topic({text}){
    return (
        <>
            <h1 style={style.h1}>{text}</h1>
        </>
    )
}
const style = {
    h1: {
        fontSize: '1.5rem',
        marginBlock: '20px',
        color: 'var(--text-heading)'
    }
}
export default Topic;
import MainTemplate from "../MainTemplate";
import Topic from "../templates/Topic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faRotate } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import  Swal  from 'sweetalert2'
import api from "../configs/api";
export default function Roles(){
    const [roleName, setRoleName] = useState()
    const [roleDescription, setRoleDescription] = useState()
    const [obtionRoles, setObtionRoles] = useState([])
    const [roleData, setRoleData] = useState([])
    const [active, setActive] = useState()
    const navigate = useNavigate()
    useEffect(()=>{
        fetchData()
        
    },[])
    const payload = {
        role_name: roleName,
        role_description: roleDescription,
        permission_temp: JSON.stringify(obtionRoles),
        is_active: active
    }
  
    const tempData = (value,isChecked) => {
        setObtionRoles(data=>{
            if(isChecked){
                return [...data, value]
            }else{
                return data.filter(filterData=>filterData != value )
            }
        })
    }    
    const saved = (e) => {
        e.preventDefault()
        Swal.fire({
            title: "ต้องการบันทึกข้อมูลตอนนี้เลยใช่ไหม",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: "Save",
            denyButtonText: `Don't save`
        }).then(successData=>{
            if(successData.isConfirmed){
                fetch(api.url_endpoint + '/role/post',{
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(payload)
                }).then(successData=>{
                    fetchData()
                })
            }
        })
    }
    const fetchData = () => {
      fetch(api.url_endpoint + '/role/table-list')
        .then(response => response.json())
        .then(data => setRoleData(data.response))
        .catch(error => console.log(error));
    };
   
    const deleteItem = (id) => {
        Swal.fire({
            title: 'ต้องการลบข้อมูล ใช่หรือไม่ !!',
            showCancelButton: true,
            confirmButtonText: "Save",
            icon: 'info'

        }).then(result=>{
            if(result.isConfirmed){
                fetch(api.url_endpoint + '/role/delete/' + id , {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => response.json())
                .then(data => {
                    fetchData()
                })
                .catch(error => console.log(error));
            }else{
                Swal.fire({
                    title: 'ยกเลิกการลบข้อมูลนี้ สำเร็จ',
                    icon: 'danger'
                })
            }
        })
        
    } 
    const editItem = async (id) =>{
        navigate(`/system/roles/edit/${id}`);
        

    }
    
    return (
        <>
                <MainTemplate>
                <div className="navbar-top">
                    {/* <span>เพิ่มบทบาท (Roles)</span> */}
                    <span>บทบาทสิทธิ์การใช้งาน (Roles)</span>
                </div>
                <div className="container-fluid">
                    <Topic text="เพิ่มบทบาท (Roles)" />
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-12">
                            <form onSubmit={saved} method="post">
                                <div className="row mt-2">
                                    <div className="col">
                                        <label htmlFor="">ชื่อบทบาท</label>
                                        <input type="text" onChange={e=>setRoleName(e.target.value)} className="form-control" />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col">
                                        <label htmlFor="">รายละเอียดบทบาท</label>
                                        <input type="text" onChange={e=>setRoleDescription(e.target.value)} className="form-control" />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col">
                                        <label htmlFor="">สิทธิ์การใช้งาน</label><br />
                                        <input type="checkbox" onChange={(e)=>tempData('create',e.target.checked)} id="create" /><label htmlFor="create">&nbsp; Create</label> <br />
                                        <input type="checkbox" onChange={(e)=>tempData('edit',e.target.checked)} id="edit" /><label htmlFor="edit">&nbsp; Edit</label> <br />
                                        <input type="checkbox" onChange={(e)=>tempData('delete',e.target.checked)}  id="delete" /><label htmlFor="delete">&nbsp; Delete</label> <br />
                                        <input type="checkbox" onChange={(e)=>tempData('views',e.target.checked)}  id="views" /><label htmlFor="views">&nbsp; Views</label> <br />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col">
                                        <label htmlFor="">เปิดใช้งาน</label><br />
                                        <input type="radio" onChange={(e)=>setActive("Y")} id="Y" name="isActive" /><label htmlFor="Y">&nbsp; ใช้งาน</label> <br />
                                        <input type="radio" onChange={(e)=>setActive("N")} id="N" name="isActive" /><label htmlFor="N">&nbsp; ไม่ใช้งาน</label> <br />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col">
                                        <button type="submit" className="btn btn-primary"><FontAwesomeIcon icon={faSave} /> บันทึกข้อมูล</button>
                                        <button type="reset" className="btn btn-warning mx-2"><FontAwesomeIcon icon={faRotate} /> ล้างฟอร์ม</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row mt-2">
                        <div className="col-12 col-md-12 col-lg-12">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>บทบาทสิทธิ์การใช้งาน</th>
                                        <th>รายละเอียด </th>
                                        <th>สถานะ</th>
                                        <th>รายการสิทธิ์</th>
                                        <th className="text-center">ตัวจัดการ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {roleData && roleData.length > 0 ? (
                                        roleData.filter(filterData=>filterData != '').map((data, index)=>{
                                            return (
                                                <tr key={index}>
                                                    <td>{index+1}</td>
                                                    <td>{data.role_name}</td>
                                                    <td>{data.role_description}</td>
                                                    <td><span className={`badge bg-${data.is_active == 'Y' ? 'success' : 'danger'}`}>{data.is_active == 'Y' ? 'ใช้งาน' : 'ไม่ใช้งาน' }</span></td>
                                                    <td>{data.permission_temp}</td>
                                                    <td className="text-center">
                                                        <button className="btn btn-warning" onClick={()=>editItem(`${data.id}`)}>แก้ไขข้อมูล</button>
                                                        <button className="btn btn-danger" onClick={()=>deleteItem(`${data.id}`)}>ลบข้อมูล</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    ): ''}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                </MainTemplate>
        </>
    )
}
